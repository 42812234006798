import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'
console.log(Layout)

// 定义静态路由
export const asyncRoutes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/login/login"),
    hidden: true
  },
  {
    path: '/paper',
    component: Layout,
    name: 'paper',
    // redirect: '/schoolCustom/index',
    meta: {
      title: '上传论文',
      icon: 'el-icon-notebook-2'
    },
    children: [{
      path: 'batchManage',
      name: 'batchManage',
      component: () => import('@/views/paperLibrary/BatchManage'),
      meta: {
        title: '上传论文',
        // icon: 'el-icon-star-on',
      },
    }, {
      path: 'paperLibrary/:id',
      name: 'paperLibrary',
      component: () => import('@/views/paperLibrary/PaperLibrary'),
      meta: {
        title: '原文库',
        icon: 'el-icon-bangzhu',
        activeMenu: '/paper/batchManage'
      },
      hidden: true
    }]
  }, 
  {
    path: '/sending',
    component: Layout,
    name: 'sending',
    // redirect: '/schoolCustom/index',
    meta: {
      title: '进度查看',
      icon: 'el-icon-view'
    },
    children: [{
      path: 'p2p',
      name: 'p2p',
      component: () => import('@/views/sending/p2p'),
      meta: {
        title: '进度查询',
        // icon: 'el-icon-s-help'
      }
    }]
  }, 

  // {
  //   path: '/review',
  //   component: Layout,
  //   name: 'review',
  //   // redirect: '/schoolCustom/index',
  //   meta: {
  //     title: '评阅书下载',
  //     icon: 'el-icon-bottom'
  //   },
  //   children: [{
  //     path: 'download',
  //     name: 'reviewDownload',
  //     component: () => import('@/views/review/reviewIndex'),
  //     meta: {
  //       title: '评阅书下载',
  //       // icon: 'el-icon-s-help'
  //     }
  //   }]
  // }, 
  {
    path: '/personalInfo',
    component: Layout,
    name: 'personalInfo',
    // redirect: '/schoolCustom/index',
    meta: {
      title: '个人信息',
      icon: 'el-icon-user'
    },
    children: [{
      path: 'password',
      name: 'password',
      component: () => import('@/views/personalInfo/password'),
      meta: {
        title: '修改密码',
        // icon: 'el-icon-s-help'
      }
    }]
  }, 
  {
    path: '/explanation',
    component: () => import('@/views/login/explanation'),
    meta: {
      title: '接审页面',
      icon: 'list'
    },
    hidden: true
  }
  // {
  //   path: '/receive',
  //   name: 'receive',
  //   component: Layout,
  //   meta: {
  //     title: '接审管理',
  //     icon: 'el-icon-star-on'
  //   },
  //   children: [{
  //       path: 'wait',
  //       name: 'wait',
  //       component: () => import('@/views/receive/Wait'),
  //       meta: {
  //         title: '待审论文管理',
  //         // icon:'el-icon-star-on'
  //       }
  //     },
  //     {
  //       path: 'progress',
  //       name: 'progress',
  //       component: () => import('@/views/receive/Progress'),
  //       meta: {
  //         title: '评审进度管理',
  //         // icon:'el-icon-star-on'
  //       }
  //     }
  //   ]
  // }, 
  // {
  //   path: '/basicInfo',
  //   name: 'basicInfo',
  //   component: Layout,
  //   meta: {
  //     title: '基础信息',
  //     icon: 'el-icon-s-grid'
  //   },
  //   children: [{
  //       path: 'expertInfo',
  //       name: 'expertInfo',
  //       component: () => import('@/views/basicInfo/ExpertInfo'),
  //       meta: {
  //         title: '本校专家库',
  //         // icon:'el-icon-star-on'
  //       }
  //     },
  //     {
  //       path: 'studentInfo',
  //       name: 'studentInfo',
  //       component: () => import('@/views/basicInfo/StudentInfo'),
  //       meta: {
  //         title: '学生信息库',
  //         // icon:'el-icon-star-on'
  //       }
  //     }
  //   ]
  // }
]

// 定义创建路由函数
const createRoute = () => new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: asyncRoutes
})


// const router = new VueRouter({
//   constantRoutes
// })

// 调用创建路由函数
const router = createRoute()

export default router